require('./bootstrap');
require('./review-widget');

import Vue from 'vue';
import 'lazysizes';
import vSelect from 'vue-select';
import VueCurrencyInput from 'vue-currency-input';
import route from 'ziggy';
import { Ziggy } from './ziggy';
import { VueReCaptcha } from 'vue-recaptcha-v3';
import { Lang } from 'laravel-vue-lang';
import Plyr from 'plyr/dist/plyr.polyfilled';
import * as VueGoogleMaps from 'vue2-google-maps';
import GmapCluster from 'vue2-google-maps/dist/components/cluster'
import { Fancybox } from "@fancyapps/ui";
require('./sweepbright');
import "@fancyapps/ui/dist/fancybox.css";
import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';

Vue.mixin({
    methods: {
        route: (name, params, absolute) => route(name, params, absolute, Ziggy),
    },
});

Vue.component('v-select', vSelect);
Vue.use(VueCurrencyInput);
Vue.use(VueTelInput);

const moment = require('moment');
require('moment/locale/nl');
Vue.use(require('vue-moment'), {
    moment,
});

Vue.prototype.svg = require('./svg');

if (window.location.pathname !== '/scan-for-cookies') {
    Vue.use(VueReCaptcha, {
        siteKey: '6LdR9rwZAAAAAE2PgXt13eE51MORxJc7pMK7xW2Z',
        loaderOptions: {
            autoHideBadge: true,
        },
    });
}

Vue.use(Lang, {
    locale: 'nl',
    fallback: 'en',
});


/**
 * Resize me script
 */
const resizeElements = document.getElementsByClassName('resize-me');
function resizeMe(resizeElements) {
    if (resizeElements.length > 0) {
        for (let i = 0; i < resizeElements.length; i++) {
            let resizeMe = resizeElements[i];
            let resizeSources = resizeMe.getElementsByClassName('resize-source');
            if (resizeSources.length > 0) {
                let resizeSource = resizeSources[0];
                resizeMe.setAttribute('style', `min-height: ${resizeSource.clientHeight}px`);
            }
        }
    }
}

// Resize me + lazysizes hook
const resizeLazyLoadedHandler = function (e) {
    if (e.target.classList.contains('resize-source')) {
        resizeMe([e.target.closest('.resize-me')]);
    }
};
document.addEventListener('lazyloaded', resizeLazyLoadedHandler, false);

const files = require.context('./components/', true, /\.vue$/i);
files.keys().map(function (key) {
    Vue.component(
        key
            .split('/')
            .pop()
            .split('.')[0],
        files(key).default,
    );
});


Vue.use(VueGoogleMaps, {
    load: {
        key: GMAP_KEY,
        libraries: 'places',
    },
});
Vue.component('GmapCluster', GmapCluster)

new Vue({
    el: '#app',

    data: () => ({
        showModal: false,
        plan: '',
        planName: '',
        propertyId: '',
        $header: null,
        stickyOpen: false,
        navigationActive: false,
        activeNav: '',
    }),

    mounted() {
        const self = this;
        let last_known_scroll_position = 0;
        let ticking = false;

        this.$header = document.querySelector('.main-header');

        Fancybox.bind(".plan-iframe", {
            type: 'iframe',
            openEffect: 'none',
            closeEffect: 'none',
        });

        var button = document.getElementById('appointment-url')
        if (window.location.href.includes('#plan-een-afspraak')) {
            button.click();
        }

        self.init();

        this.$nextTick(function () {
            document.querySelectorAll('.media #player').forEach(video => {
                new Plyr(video);

                resizeMe(resizeElements);
            });
        });

        window.addEventListener('resize', () => {
            self.init();

            resizeMe(resizeElements);
        });

        window.addEventListener('orientationchange', () => {
            self.init();
        });

        const boxes = document.querySelectorAll('.office-video');
        boxes.forEach(box => {
            box.addEventListener('mouseover', function handleClick(event) {
                event.target.play();
            });
            box.addEventListener('mouseleave', function handleClick(event) {
                box.pause();
            });
        });

        window.addEventListener('scroll', function (e) {
            last_known_scroll_position = window.scrollY;

            if (!ticking) {
                window.requestAnimationFrame(function () {
                    self.handleScrolled(last_known_scroll_position);
                    ticking = false;
                });

                ticking = true;
            }

            if (window.innerWidth < 1024) {
                boxes.forEach(box => {
                    var position = box.getBoundingClientRect();
                    if (position.top >= 0 && position.bottom <= window.innerHeight) {
                        box.play();
                    } else {
                        box.pause()
                    }
                });
            }
        });

    },

    methods: {
        isTouchDevice() {
            return (('ontouchstart' in window) ||
                (navigator.maxTouchPoints > 0) ||
                (navigator.msMaxTouchPoints > 0));
        },
        init() {
            let vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        },

        handleScrolled(pos) {
            if (pos >= 75) {
                this.$header.classList.add('scrolled');
            }
            if (pos < 75) {
                this.$header.classList.remove('scrolled');
            }
        },
    },
});

window.checkCopandiFirstVisit = () => {
    var cookieName = "visited";
    var existingCookie = getCookie(cookieName);

    if (!existingCookie) {

        var expirationDate = new Date();
        expirationDate.setDate(expirationDate.getDate() + 14);

        document.cookie = cookieName + "=true; expires=" + expirationDate.toUTCString() + "; path=/";

        return true
    } else {
        return false
    }
}

function getCookie(cookieName) {
    var name = cookieName + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var cookieArray = decodedCookie.split(';');

    for (var i = 0; i < cookieArray.length; i++) {
        var cookie = cookieArray[i].trim();
        if (cookie.indexOf(name) == 0) {
            return cookie.substring(name.length, cookie.length);
        }
    }

    return null;
}


